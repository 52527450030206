<template>
	<div class="flx-1 bk-co-f5">
		<div class="mg-t-10 bk-co-ff">
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16">
				<div class="mg-r-auto ov-fl-h" style="-webkit-line-clamp: 1;">{{dataList.inquiretitle}}</div>
			</div>
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c ft-co-99">
				<div class="mg-r-auto">
					问卷编号:{{dataList.inquireid}}
				</div>
				<div>
					发布时间:{{util.formatDate('',dataList.createtime)}}
				</div>
			</div>
		</div>
		<div class="mg-t-10 bk-co-ff">
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16">
				<div>
					<span class="zmt_iconfont ft-sz-22">&#xe606;</span>
				</div>
				<div class="mg-l-5">简介</div>
			</div>
			<div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14">
				<span class="pd-x-10"></span> {{dataList.abstract}}
			</div>
		</div>
		<div class="mg-t-10 bk-co-ff" v-for="(item,index) in evaluationStarList" :key="index">
			<div class="pd-x-10 pd-t-10 pd-b-10 bd-bott ft-sz-16">
				<span class="ft-co-fd" v-if="item.questionrequire == 1">*</span>{{item.questionlabe}}
			</div>
			<div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14" v-if="item.questionstyle == 'star'">
				<span class="mg-r-10" v-for="(items,indexs) in item.options" :key="indexs"><van-icon name="star" :color="item.inxsStatr >= indexs ?'#f5e207':'#999999'" @click="starClick(index,indexs)" /></span>
			</div>
			<div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14" v-if="item.questionstyle == 'score'">
				<span class="mg-r-10" v-for="(items,indexs) in item.options" :key="indexs"><van-icon name="good-job" :color="item.inxsStatr >= indexs ?'#f52944':'#999999'" @click="starClick(index,indexs)" /></span>
			</div>
			<div class="pd-x-10 pd-b-10 ft-sz-14" v-if="item.questionstyle == 'number'">
				<div class="pd-b-10 pd-x-10 pd-t-10 mg-t-10 ft-sz-16 bd-r-5" :class="item.inxsStatr == indexs ?'bk-co-fd ft-co-ff':''" v-for="(items,indexs) in item.options" :key="indexs" @click="starClick(index,indexs)" style="box-shadow: 0 2px 5px 0 #d1d1d1;">
					{{items.options}}
				</div>
			</div>
		</div>
		<div class="mg-t-10 bk-co-ff">
			<div class="pd-x-10 pd-t-10 pd-b-10 bd-bott ft-sz-16">
				填写信息
			</div>
			<div class="">
				<van-field v-model="evaluationStarFrom.remarks" label="姓名" />
				<van-field v-model="evaluationStarFrom.mobile" type="tel" label="手机号" />
				<van-field v-model="evaluationStarFrom.dataTime" label="提交时间" />
			</div>
			<div class="pd-x-10">
				<div class="mg-t-30">
					<button class="pd-y-10 w-100 ft-co-ff ft-sz-16" type="button" @click="getEvaluationStarSubimt">提交</button>
				</div>
			</div>
			<div class="pd-y-15"></div>
		</div>
	</div>
</template>
<style scoped>
	button {border: none;color: #FFFFFF;border-radius: 30px;background: -webkit-linear-gradient(#f7514d, #fd704d);}
	button:focus {outline: none !important;}
	.van-icon{font-size: 22px !important;}
</style>
<script>
	import http from '../../../lib/api.js'
	import util from '../../../lib/util.js'
	import axios from 'axios'
	import {
		Toast
	} from 'vant';
	var {
		adminGeneral,
		huoDongJuAcions 
	} = http
	export default {
		name: 'Home',
		components: {},
		props: {
			userdata: {
				type: Object,
				default () {
					return {
						realname:'',
						usermobile:'',
						userid:'' 
					}
				}
			}
		},
		data() {
			return {
				util:util,
				dataList:{},
				evaluationStarList:[],
				evaluationStarFrom:{
					remarks:'',
					mobile:'',
					dataTime:''
				},
			}
		},
		created(){},
		mounted() {
			var s = this;
			var chuo = new Date()
			var year = chuo.getFullYear();
			var month = chuo.getMonth() + 1;
			var day = chuo.getDate();
			s.evaluationStarFrom.dataTime = year + "-" + month + "-" + day;
			s.evaluationStarFrom.remarks = s.userdata.realname;
			s.evaluationStarFrom.mobile = s.userdata.usermobile;
			s.userVoteList();
		},
		activated() {},
		methods: {
			userVoteList(){/*用户获取投票列表 2001530*/
				var s = this;
				var condition = {};
				condition = Object.assign(condition, {
					page_index: 0,
					page_size: 1,
					"companyid":s.$store.state.companyid,
					"productid":s.$store.state.productid,
					"inquireid":s.$route.params.id
				})
				adminGeneral.userSurveyList.http({
					condition
				}).then(data => {
					if (data.getret === 0) {
						s.dataList = data.list[0];
						s.getEvaluationStarFrom();
					}
				})
			},
			getEvaluationStarFrom(){
				var s = this;
				var condition = {};
				condition = Object.assign(condition, {
					page_index: 0,
					page_size: 1000,
					"companyid":s.$store.state.companyid,
					"productid":s.$store.state.productid,
					"inquireid":s.$route.params.id
				})
				huoDongJuAcions.getEvaluationStarForm.http({
					condition
				}).then(data => {
					if (data.getret === 0) {
						var arr = []
						data.list.forEach((v,k)=>{
							v.optionsids = [];
							v.othertext = '';
							v.inxsStatr = -1;
							v.options = v.options.sort(s.compare('optionsext'))
						})
						s.evaluationStarList = data.list;
					}
				})
			},
			getEvaluationStarSubimt(){/*提交*/
				var s = this;
				var info = {
					"action":'2001532'
				}
				var list = []
				s.evaluationStarList.forEach((v,k)=>{
					if(typeof(v.optionsids) == 'string'){
						var arr = [v.optionsids]
					}else{
						var arr = v.optionsids
					}
					list.push({
						"questionid":v.questionid,
						"optionsids":arr,
						"othertext":v.othertext
					})
				})
				if(s.dataList.isrealname != 0){
					info = Object.assign(info, {
						"info":{
							"inquireid":s.$route.params.id,
							"vuid":s.dataList.vuid + '',
						},
						"list":list,
						"user":{	
							"realname":s.evaluationStarFrom.remarks,
							contact:s.evaluationStarFrom.mobile,
							userid:s.$store.state.user.userid
						  },
						  "ui":{
						  	"userid":s.$store.state.user.userid,
						  	"token": s.$store.state.user.token,
						  }
					})
				}else{
					info = Object.assign(info, {
						"info":{
							"vuid":s.dataList.totalnumbers + '',
							"inquireid":s.$route.params.id
						},
						"list":list,
						"user":{
							userid:''
						  },
						  "ui":{
						  	"userid":s.$store.state.user.userid,
						  	"token":s.$store.state.user.token,
						  }
					})
				}
				axios.post(s.util.H5API+'?name=getEvaluationStarSubimt',JSON.stringify(info))
				.then(function (response) {
					if(response.data.getret == 0){
						Toast(response.data.msg);
						s.$router.go(-1);
					}
				})
				.catch(function (error) {
					console.log(error);
				});
			},
			starClick(inx,inxs){
				let s = this;
				// console.log(s.evaluationStarList[inx].options[inxs].optionsext)
				s.evaluationStarList[inx].inxsStatr = inxs;
				s.evaluationStarList[inx].othertext = s.evaluationStarList[inx].options[inxs].optionsext
			},
			compare(property){
			    return function(a,b){
			        var value1 = a[property];
			        var value2 = b[property];
			        return value1 - value2;
			    }
			}
		},
		destroyed() {}
	}
</script>
