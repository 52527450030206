<template>
	<div class="flx-1 bk-co-f5">
		<img v-if="otherList.banner != ''" class="w-100" :src="otherList.banner" alt="">
		<div class="mg-t-10 bk-co-ff">
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16">
				<div class="mg-r-auto dis-flex-row al-c ft-co-fd">
					<span class="zmt_iconfont ft-sz-22">&#xe606;</span>
					<span>说明</span>
				</div>
				<div class="ft-sz-12 ft-co-99">
					{{otherList.createtime}} -- {{otherList.endtime}}
				</div>
			</div>
			<div class="pd-x-10 pd-t-10 pd-b-10 bd-bott ft-sz-16">
				<span class="pd-x-10"></span>
				{{otherList.abstract}}
			</div>
		</div>
		<div class="mg-t-10 bk-co-ff">
			<div class="pd-x-10 pd-t-10 pd-b-10 bd-bott ft-sz-16">
				<div class="dis-flex-row al-c ft-co-fd">
					<span class="zmt_iconfont ft-sz-22">&#xe606;</span>
					<span>填写信息</span>
				</div>
			</div>
			<van-form @submit="getEvaluationStarSubimt">
				<div class="pd-x-10 pd-t-10 pd-b-10 bd-bott ft-sz-16" v-for="(item,index) in otherList.list" :key="index">
					<div class="pd-b-10 ft-sz-16" v-if="item.type != 8">
						<span class="ft-co-fd" v-if="item.questionrequire == 1">*</span>{{item.control_name}}
					</div>
					<!-- 单行文本 -->
					<van-field v-if="item.type == 1" v-model="item.val" name="用户名" :placeholder="'请输入'+item.control_name" />
					<!-- 多行文本 -->
					<van-field v-if="item.type == 2" v-model="item.val" rows="3" autosize type="textarea" maxlength="200" placeholder="请输入内容"
					 show-word-limit />
					<!-- 下拉选择 -->
					<van-dropdown-menu v-if="item.type == 3">
						<van-dropdown-item v-model="item.val" :options="arrObj(JSON.parse(item.values))" />
					</van-dropdown-menu>
					<!-- 单选框 -->
					<van-radio-group v-model="item.val" v-if="item.type == 4">
						<van-radio class="mg-t-10 dis-flex-row al-c" v-for="(items,indexs) in JSON.parse(item.values)" :key="indexs"
						 :name="items">{{items}}</van-radio>
					</van-radio-group>
					<!-- 复选框 -->
					<van-field name="checkboxGroup" v-if="item.type == 5">
						<template #input>
							<van-checkbox-group v-model="item.val" direction="horizontal">
								<van-checkbox v-for="(items,indexs) in JSON.parse(item.values)" :key="indexs" :name="items" shape="square">{{items}}</van-checkbox>
							</van-checkbox-group>
						</template>
					</van-field>
					<!-- 上传 -->
					<van-uploader v-if="item.type == 6" :after-read="afterRead" v-model="fileList" multiple :max-count="5" :before-read="openClick(index)" />
					<!-- 数值 -->
					<van-field v-if="item.type == 7" v-model="item.val" type="number" :placeholder="'请输入'+item.control_name" />
					<!-- 日期 -->
					<div class="dis-flex-row al-c" v-if="item.type == 8">
						<van-cell :title="item.control_name" :value="item.val" @click="show1 = true" />
						<van-icon class="mg-l-10" name="arrow" />
						<van-calendar v-model="show1" @confirm="confirmClick" @open="openClick(index)" />
					</div>
					<!-- 手机号 -->
					<van-field v-if="item.type == 10" v-model="item.val" type="tel" :placeholder="'请输入'+item.control_name" />
					<!-- 民族选择 -->
					<van-dropdown-menu v-if="item.type == 11">
						<van-dropdown-item v-model="item.val" :options="arrObj(JSON.parse(item.values))" />
					</van-dropdown-menu>
					<!-- 地址选择 -->
					<van-field v-if="item.type == 12" v-model="item.val" is-link readonly :placeholder="'请输入'+item.control_name"
					 @click="show = true" />
					 <van-popup v-if="item.type == 12" v-model="show" round position="bottom">
					 	<van-cascader v-model="cascaderValue" title="请选择所在地区" @change="openClick(index)" @finish="onFinish" :options="options" :field-names="{text: 'label', value: 'value', children: 'children'}" @close="show = false" />
					 </van-popup>
					<!-- 评分 -->
					<van-rate v-if="item.type == 13" v-model="item.val" class="mg-t-10" color="#ffd21e" void-icon="star" void-color="#eee" />
				</div>
				<div style="margin: 16px;">
					<van-button round block type="info" native-type="submit">提交</van-button>
				</div>
			</van-form>
			<div class="pd-y-15"></div>
		</div>
	</div>
</template>
<style scoped>
	.van-cell{padding: 0 !important;}
	.van-cell::after{border: none !important;}
	.van-cell__title{font-size: 16px !important;}
	.van-icon{color: #969799;}
</style>
<script>
	import http from '../../../lib/api.js'
	import util from '../../../lib/util.js'
	import axios from 'axios'
	import {
		Toast,
		Form,
		RadioGroup,
		Radio,
		Checkbox,
		CheckboxGroup
	} from 'vant';
	var {
		huoDongJuAcions,
		adminGeneral
	} = http
	export default {
		name: 'Home',
		components: {},
		data() {
			return {
				util: util,
				dateTime:'',
				dateTimeIndex:0,
				otherList: {},
				show: false,
				show1: false,
				cascaderValue:'',
				options: [],
				currentCity:[],
				fileList:[]
			}
		},
		created() {},
		mounted() {
			this.getOtherList();
			this.getCascader();
		},
		activated() {},
		methods: {
			getEvaluationStarSubimt(e) { /*提交*/
				var s = this;
				var info = {}
				console.log(s.otherList)
				// huoDongJuAcions.getEvaluationStarSubimt.http(info).then(data => {
				// 	if (data.getret === 0) {
				// 		Toast('提交成功')
				// 		if(s.$route.params.type == 1){
				// 			setTimeout(function(){
				// 				s.$router.go(-1);
				// 			},300)
				// 		}else{
				// 			setTimeout(function(){
				// 				s.$router.push('/');
				// 			},300)
				// 		}
				// 	}
				// })
			},
			getOtherList() {
				var s = this;
				var condition = {};
				condition = Object.assign(condition, {
					"companyid": s.$store.state.companyid,
					"productid": s.$store.state.productid,
					"formid": s.$route.params.id,
					"page_index": 0,
					"page_size": 1
				})
				huoDongJuAcions.getOtherList.http({
					condition
				}).then(data => {
					if (data.getret === 0) {
						data.list[0].list.forEach((v, k) => {
							if (v.type == 3 || v.type == 11) {
								var arr = s.arrObj(JSON.parse(v.values))
							}
							v.val = v.type == 13 ? null : v.type == 5 || v.type == 6 ? [] : v.type == 4 && v.control_name == '性别' ? v.default_value :
								v.type == 3 ? arr[0].value : v.type == 11 ? arr[0].value : '';
						})
						s.otherList = data.list[0]
					}
				})
			},
			arrObj(val) {
				var arr = []
				val.forEach((v, k) => {
					arr.push({
						text: v,
						value: k
					})
				})
				return arr
			},
			formatDate(date) {
			  return `${date.getMonth() + 1}/${date.getDate()}`;
			},
			confirmClick(e){
				let s = this;
				s.show1 = false;
				s.dateTime = s.formatDate(e);
				s.otherList.list[s.dateTimeIndex].val = s.dateTime;
				console.log(s.dateTime)
			},
			openClick(e){/*当前对象*/
				this.dateTimeIndex = e;
			},
			afterRead(e){
				var s = this;
				 let formData= new FormData();
				 formData.append('action','3001321');
				 formData.append('verify','1072203850');
				 formData.append('file',e.file)
				axios.post(s.util.H5API+'?name=handleBeforeUpload',formData)
				.then(function (response) {
					if(response.data.getret == 0){
						s.otherList.list[s.dateTimeIndex].val.push(s.util.H5API+response.data.info.filepath);
					}
				})
				.catch(function (error) {
					console.log(error);
				});
			},
			getCascader() { /*省*/
				var s = this;
				var condition = {
					"companyid": s.$store.state.companyid,
					"productid": s.$store.state.productid,
				}
				huoDongJuAcions.getCascader.http({condition}).then(data => {
					if (data.getret === 0) {
						s.options = data.list[0].children;
					}
				})
			},
			onFinish({selectedOptions}){
				var s = this;
				s.show = false;
				// s.otherList.list[s.dateTimeIndex].val = selectedOptions.map((option) => option.text).join('/');
				console.log(selectedOptions.map((option) => option.text).join('/'))
			},
			cityChange(value) { /*市*/
				let c = this.options.filter(item => {
					return item.label == value
				})
				if (c.length >= 0) {
					this.currentCity = c[0].children;
				} else {
					this.currentCity = [];
				}
			},
		},
		destroyed() {}
	}
</script>
