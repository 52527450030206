<template>
	<div class="h-100 dis-flex-com">
		<div v-if="searchStatus">
			<van-nav-bar title="活动" left-text="返回" left-arrow @click-left="navBarLeft" @click-right="navBarRight" placeholder fixed>
			  <template #right>
			    <van-icon name="search" />
			  </template>
			</van-nav-bar>
		</div>
		<div class="bd-bott" v-if="!searchStatus">
			<form action="/">
			  <van-search
			    v-model="condition.partyname"
				input-align="center"
			    show-action
			    placeholder="请输入搜索关键词"
			    @input="onSearch"
			    @cancel="onCancel"
			  />
			</form>
		</div>
		<div class="flx-1 bk-co-f5" ref="evabox" @scroll="Scroll" style="overflow-y: auto;">
			<div class="mg-t-10 bk-co-ff" v-for="(item,index) in dataList" :key="item.partyid">
				<div class="dis-flex-row ft-co-ff">
					<div class="mg-l-auto dis-flex-row">
						<div class="po-r" style="top: 4px;">
							<span class="pd-x-10 ft-sz-10 pd-t-5 pd-b-5" :class="!!jisuanTime(item.enteredtime)?'bk-co-fd4':'bk-co-99'" style="border-bottom-left-radius: 20px;">
								{{util.formatDate('cn',item.begintime)}}
							</span>
						</div>
						<div class="pd-x-10 pd-t-5 pd-b-5 ft-sz-14" :class="!!jisuanTime(item.enteredtime)?'bk-co-fd':'bk-co-99'" style="border-bottom-left-radius: 20px;">
							活动时间
						</div>
					</div>
				</div>
				<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16 ov-fl-h" style="-webkit-line-clamp: 1;">
					{{item.partyname}}
				</div>
				<div class="pd-x-10 pd-t-10 dis-flex-row">
					<div class="bk-co-f5 flx-1 pd-x-10 pd-t-10 pd-b-10 bd-r-5 ft-sz-14" style="color: #5f5f5f;" v-html="item.abstract"></div>
				</div>
				<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c ft-co-99 ft-sz-20">
					<div class="ft-co-fd flx-1">
						参加人数:{{item.totalnumber}}
					</div>
					<div>
						<div class="bd-r-30 ft-co-ff pd-t-5 pd-b-5 ft-sz-14 txt-c baoMingCliass" :class="!!jisuanTime(item.enteredtime)?'bk-co-fd':'bk-co-99'" @click="Details(item,!!jisuanTime(item.enteredtime))">
							{{!!jisuanTime(item.enteredtime)?'我要报名':'活动结束'}}
						</div>
					</div>
				</div>
			</div>
			<div class="txt-c ft-co-99 ft-sz-16 pd-y-10" v-if="flag && !loading">
				没有更多数据了
			</div>
		</div>
	</div>
</template>
<script>
	import http from '../../lib/api.js'
	import util from '../../lib/util.js'
	import CompanyList from '../../components/companyList.vue';
	import {
		Toast,
		NavBar
	} from 'vant';
	var he = require('he');
	var {
		adminGeneral
	} = http
	export default {
		name: 'Activity',
		components: {},
		data() {
			return {
				util:util,
				searchStatus:true,/*搜素框显示*/
				dataList:[],
				condition:{
					page_index:0,
					page_size:10,
					partyname: "",
					status: 1
				},
				flag:false,
				loading:false,
				total:0
			}
		},
		created(){},
		mounted() {
			this.userSurveyList();
		},
		activated() {},
		methods: {
			userSurveyList(){/*用户获取调查列表 2001530*/
				var s = this;
				var {condition} = s;
				condition = Object.assign(condition, {
					"companyid":s.$store.state.companyid,
					"productid":s.$store.state.productid,
				})
				adminGeneral.activityList.http({
					condition
				}).then(data => {
					if (data.getret === 0) {
						if(data.total>0){
						  if(data.condition.page_index > '0'){
							  s.dataList = s.dataList.concat(data.list)
						  }else{
							  s.dataList = data.list;
						  }
						  s.total = data.total
						}
					}
				})
			},
			onSearch(val){/*搜索*/
				this.userSurveyList();
			},
			onCancel(){/*取消*/
				this.searchStatus = true;
			},
			navBarLeft(){
				this.$router.go(-1)
			},
			navBarRight(){
				this.searchStatus = false;
			},
			Details(val,status){
				var s = this;
				if(status){
					this.$router.push('/details'+'/'+s.$store.state.companyid+'/'+s.$store.state.productid+'/'+val.isrealname+'/4'+'/'+val.partyid+'/1')
				}
			},
			jisuanTime(val){
				let s = this;
				if(new Date().getTime()/1000 > val) return false;
				var data = val - (new Date().getTime()/1000);
				return Math.floor(data/(24*60*60))
			},
			Scroll(e){
				let s = this;
				const ele = e.srcElement ? e.srcElement : e.target
				 // 监听滚动到div底部
				if (ele.scrollTop + ele.offsetHeight > ele.scrollHeight - 100) {
				  if (s.flag === false) {//避免多次请求 加锁 该次请求完成 才能再次发起请求
					s.flag = true
					if(s.total > s.dataList.length){
						s.condition.page_index ++
						s.userSurveyList();
					}
				  }
				}
			}
		},
		destroyed() {}
	}
</script>
<style scoped>
	.van-icon{
		font-size: 18px !important;
	}
	.baoMingCliass{
		width: 100px;
	}
</style>