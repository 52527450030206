<template>
	<div class="flx-1 bk-co-f5">
		<div class="mg-t-10 bk-co-ff">
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16">
				<div class="mg-r-auto ov-fl-h" style="-webkit-line-clamp: 1;">{{dataList.inquiretitle}}</div>
			</div>
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c ft-co-99 po-r">
				<span v-if="statucode==false && curentime < dataList.begintime" class="zmt_iconfont po-a statusIcon" style="color:blue;">&#xe6c4;</span>
				<span v-else-if="statucode==true && vuidstatus==true" class="zmt_iconfont po-a statusIcon">&#xe6bf;</span>
				<span v-else-if="statucode==true && curentime<dataList.endtime && vuidstatus==false" class="zmt_iconfont po-a statusIcon" style="color:blue;">&#xe6c0;</span>
				<span v-else-if="statucode==true && curentime<dataList.endtime && vuidstatus==true" class="zmt_iconfont po-a statusIcon">&#xe6c1;</span>
				<div class="mg-r-auto">
					问卷编号:{{dataList.inquireid}}
				</div>
				<div>
					发布时间:{{util.formatDate('',dataList.createtime)}}
				</div>
			</div>
		</div>
		<div class="mg-t-10 bk-co-ff">
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16">
				<div>
					<span class="zmt_iconfont ft-sz-22">&#xe606;</span>
				</div>
				<div class="mg-l-5">简介</div>
			</div>
			<div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14">
				<span class="pd-x-10"></span> {{dataList.abstract}}
			</div>
			<div class="mg-t-10 bk-co-ff" v-for="(item,index) in evaluationStarList" :key="index">
				<div class="pd-x-10 pd-t-10 pd-b-10 bd-bott ft-sz-16">
					<span class="ft-co-fd" v-if="item.questionrequire == 1">*</span>{{item.questionlabe}} <span class="ft-co-99">{{item.questiontype == '0'?'（单选）':item.questiontype == '1'?'（多选）':''}}</span>
				</div>
				<div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14" v-if="item.questiontype == '0'">
					<van-radio-group v-model="item.optionsids">
					  <!-- <div class="bd-bott pd-b-10" v-if="item.questionurl != ''">
					  	<img class="w-100" :src="item.questionurl" alt="">
					  </div> -->
					  <div class="pd-b-10 pd-x-10 pd-t-10 mg-t-10 ft-sz-16 bd-r-5" v-for="(items,indexs) in item.options" :key="indexs" style="box-shadow: 0 2px 5px 0 #d1d1d1;">
						<van-radio :name="items.optionsid" :disabled="vuidstatus">
							<div>
								{{items.options}}
							</div>
							<div class="pd-t-10">
								<van-image
								  width="3rem"
								  height="3rem"
								  fit="cover"
								  :src="item.optionsurl"
								/>
							</div>
						</van-radio>
					  </div>
					</van-radio-group>
				</div>
				<div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14" v-if="item.questiontype == '1'">
					<van-checkbox-group v-model="item.optionsids" direction="horizontal">
						<!-- <div class="bd-bott pd-b-10" v-if="item.questionurl != ''">
							<img class="w-100" :src="item.questionurl" alt="">
						</div> -->
						<div class="pd-b-10 pd-x-10 pd-t-10 mg-t-10 ft-sz-16 bd-r-5" v-for="(items,indexs) in item.options" :key="indexs" style="box-shadow: 0 2px 5px 0 #d1d1d1;">
							<van-checkbox :name="items.optionsid" shape="square" :disabled="vuidstatus">
								<div>
									{{items.options}}
								</div>
								<div class="pd-t-10">
									<van-image
									  width="3rem"
									  height="3rem"
									  fit="cover"
									  :src="items.optionsurl"
									/>
								</div>
							</van-checkbox>
						</div>
					</van-checkbox-group>
				</div>
				<div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14" v-if="item.questiontype == '2'">
					<!-- <div class="bd-bott pd-b-10" v-if="item.questionurl != ''">
						<img class="w-100" :src="item.questionurl" alt="">
					</div> -->
					<van-field v-model="item.othertext" :disabled="vuidstatus" placeholder="请输入内容" />
				</div>
				<div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14" v-if="item.questiontype == '3'">
					<!-- <div class="bd-bott pd-b-10" v-if="item.questionurl != ''">
						<img class="w-100" :src="item.questionurl" alt="">
					</div> -->
					<van-field
					  v-model="item.othertext"
					  :disabled="vuidstatus"
					  rows="3"
					  autosize
					  type="textarea"
					  maxlength="200"
					  placeholder="请输入内容"
					  show-word-limit
					/>
				</div>
			</div>
			<div class="pd-x-10" v-if="!vuidstatus">
				<div class="mg-t-30">
					<button class="pd-y-10 w-100 ft-co-ff ft-sz-16" type="button" @click="getEvaluationStarSubimt">提交</button>
				</div>
			</div>
			<div class="pd-y-15"></div>
		</div>
	</div>
</template>
<style scoped>
	button {border: none;color: #FFFFFF;border-radius: 30px;background: -webkit-linear-gradient(#f7514d, #fd704d);}
	button:focus {outline: none !important;}
	.van-checkbox-group--horizontal{display: block !important;}
</style>
<script>
	import http from '../../../lib/api.js'
	import util from '../../../lib/util.js'
	import axios from 'axios'
	import {
		Toast
	} from 'vant';
	var {
		adminGeneral,
		huoDongJuAcions 
	} = http
	export default {
		name: 'Home',
		props: {
			userdata: {
				type: Object,
				default () {
					return {
						realname:'',
						contact:'',
						userid:''
					}
				}
			}
		},
		components: {},
		data() {
			return {
				util:util,
				dataList:{},
				evaluationStarList:[],
				statucode:true,/*开始/未开始*/
				vuidstatus:false,/*禁止输入*/
				curentime:0,
				vuid:0
			}
		},
		created(){},
		mounted() {
			let datestr=parseInt(Number(new Date())/1000)
			this.curentime = datestr;
			this.userVoteList();
		},
		activated() {},
		methods: {
			userVoteList(){/*用户获取投票列表 2001530*/
				var s = this;
				var condition = {};
				condition = Object.assign(condition, {
					page_index: 0,
					page_size: 1,
					"companyid":s.$store.state.companyid,
					"productid":s.$store.state.productid,
					"inquireid":s.$route.params.id
				})
				adminGeneral.userSurveyList.http({
					condition
				}).then(data => {
					if (data.getret === 0) {
						s.dataList = data.list[0];
						s.getEvaluationStarFrom();
					}
				})
			},
			getEvaluationStarFrom(){
				var s = this;
				var condition = {};
				condition = Object.assign(condition, {
					page_index: 0,
					page_size: 1000,
					"companyid":s.$store.state.companyid,
					"productid":s.$store.state.productid,
					"inquireid":s.$route.params.id
				})
				huoDongJuAcions.getEvaluationStarForm.http({
					condition
				}).then(data => {
					if (data.getret === 0) {
						var arr = []
						data.list.forEach((v,k)=>{
							v.optionsids = [];
							v.othertext = '';
							v.inxsStatr = -1;
							v.options = v.options.sort(s.compare('optionsext'))
							// v.options.forEach((l,j)=>{
							// 	l.optionsid = l.optionsid + ''
							// })
						})
						s.evaluationStarList = data.list;
						//获取是否已提交调查状态
						if(s.$store.state.user.userid != ''){
							s.getformstatus();
						}
					}else if(data.getret==11){
					    /*未开始*/
						s.statucode = false;
					}else if(data.getret==12){
						/*结束*/
						s.vuidstatus = true;
					}else if(data.getret==1000){//必须登录时返回首页
					   s.$router.push('/login')
					}
				})
			},
			getEvaluationStarSubimt(){/*提交*/
				let s = this;
				var info = {
					"action":'2001532'
				}
				var list = []
				s.evaluationStarList.forEach((v,k)=>{
					if(typeof(v.optionsids) == 'string'){
						var arr = [v.optionsids]
					}else{
						var arr = v.optionsids
					}
					list.push({
						"questionid":v.questionid,
						"optionsids":arr,
						"othertext":v.othertext
					})
				})
				if(s.dataList.isrealname != 0){
					info = Object.assign(info, {
						"info":{
							"inquireid":s.$route.params.id,
							"vuid":s.dataList.vuid + '',
						},
						"list":list,
						"user":{	
							realname:s.evaluationStarFrom.nakeName,
							contact:s.evaluationStarFrom.mobile,
							userid:s.$store.state.user.userid
						  },
						  "ui":{
						  	"userid":store.state.user.userid,
						  	"token": store.state.user.token,
						  }
					})
				}else{
					info = Object.assign(info, {
						"info":{
							"vuid":s.dataList.totalnumbers + '',
							"inquireid":s.$route.params.id
						},
						"list":list,
						"user":{
							userid:''
						  },
						  "ui":{
						  	"userid":s.$store.state.user.userid,
						  	"token":s.$store.state.user.token,
						  }
					})
				}
				axios.post(s.util.H5API+'?name=getEvaluationStarSubimt',JSON.stringify(info))
				.then(function (response) {
					if(response.data.getret == 0){
						Toast(response.data.msg);
						s.$router.go(-1);
					}
				})
				.catch(function (error) {
					console.log(error);
				});
			},
			getformstatus(){
				var s = this;
				var condition = {};
				condition = Object.assign(condition, {
					"userid":s.$store.state.user.userid,
					"productid":s.$store.state.productid,
					"inquireid":s.$route.params.id
				})
				huoDongJuAcions.getformstatus.http({
					condition
				}).then(data => {
					if(data.getret==0){
					  s.vuid = data.info && data.info.vuid || 0;
					  console.log(s.vuid)
					  /*没到结束时间时查看提交情况*/
					  // console.log(s.curentime,s.dataList.endtime)
					  if(s.vuid >0 && s.curentime<s.dataList.endtime){
					    /*禁止输入*/
					   s.vuidstatus = true;
					    /*已提交调查的情况*/
					    s.getUsereport(data.info.vuid);
					  }
					}else{
					  s.vuid = 0;
					}
				})
			},
			getUsereport(vuid){
				var s = this;
				var condition = {};
				condition = Object.assign(condition, {
					vuid:vuid,
					page_index:0,
					page_size:1000,
					"companyid":s.$store.state.companyid,
					"inquireid":s.$route.params.id
				})
				huoDongJuAcions.getUsereport.http({
					condition
				}).then(data => {
					if (data.getret === 0) {
						s.evaluationStarList.forEach((v,k)=>{
							if(v.questiontype == '0'){
								v.optionsids = data.list[k].coloect_options.optionsids[0]*1
							}else{
								var arr = []
								data.list[k].coloect_options.optionsids.forEach((l,j)=>{
									arr.push(l*1)
								})
								v.optionsids = arr
							}
							v.othertext = data.list[k].coloect_options.othertext
						})
						s.vuidstatus = true;
					}
				})
			},
			compare(property){
			    return function(a,b){
			        var value1 = a[property];
			        var value2 = b[property];
			        return value1 - value2;
			    }
			}
		},
		destroyed() {}
	}
</script>
