<template>
	<div class="h-100 dis-flex-com">
		<div>
			<van-nav-bar :title="$route.params.title == 1 ?'调查':$route.params.title == 2 ?'评星':$route.params.title == 3 ?'投票':$route.params.title == 4 ?'活动':'其他'" left-text="返回" left-arrow @click-left="navBarLeft" placeholder fixed>
			</van-nav-bar>
		</div>
		<!-- 调查 -->
		<div class="flx-1 dis-flex-com" v-if="$route.params.title == 1">
			<survey></survey>
		</div>
		<!-- 评星 -->
		<div class="flx-1 dis-flex-com" v-if="$route.params.title == 2 && status">
			<evaluationStar :userdata="userData"></evaluationStar>
		</div>
		<!-- 投票 -->
		<div class="flx-1 dis-flex-com" v-if="$route.params.title == 3 && status">
			<vote :userdata="userData"></vote>
		</div>
		<!-- 活动 -->
		<div class="flx-1 dis-flex-com" v-if="$route.params.title == 4 && status">
			<activity :userdata="userData"></activity>
		</div>
		<!-- 其他 -->
		<div class="flx-1 dis-flex-com" v-if="$route.params.title == 5">
			<other></other>
		</div>
	</div>
</template>
<script>
	import vote from './component/vote.vue'
	import other from './component/other.vue'
	import survey from './component/survey.vue'
	import activity from './component/activity.vue'
	import evaluationStar from './component/evaluationStar.vue'
	import http from '../../lib/api.js'
	var {
		adminGeneral
	} = http
	export default {
		name: 'Home',
		components: {vote,other,survey,evaluationStar,activity},
		data() {
			return {
				userData: {
					realname: "",
					usermobile: "",
					username: "",
				},
				status:false
			}
		},
		created(){},
		mounted() {
			var s = this;
			if(s.$route.params.real == 1){
				if(s.$store.state.user.userid == ''){
					s.$router.push('/login?title='+s.$route.params.title)
				}
			}
			s.userInfo();
		},
		methods: {
			userInfo() {/*获取用户信息*/
				var s = this;
				adminGeneral.userInfo.http({
					"companyid": s.$store.state.companyid,
					"userid": s.$store.state.user.userid,
				}).then(res => {
					if (res.getret === 0) {
						s.userData = res.info.user
						s.status = true
					}
				})
			},
			navBarLeft(){
				var s = this;
				if(s.$route.params.type == 1){
					s.$router.go(-1);
				}else{
					s.$router.push('/');
				}
			}
		},
		destroyed() {}
	}
</script>
<style scoped>
	button {border: none;color: #FFFFFF;border-radius: 30px;background: -webkit-linear-gradient(#f7514d, #fd704d);}
	button:focus {outline: none !important;}
	.van-field__body{background-color: #F5F5F5 !important;}
	.van-icon{font-size: 22px !important;}
	.van-checkbox-group--horizontal{display: block !important;}
	.van-checkbox,.van-radio{align-items: flex-start !important;}
	.touPiao{width: 172px;padding: 0 5px;}
	.touPiao .img-w{height: 120px;overflow: hidden;}
	.touP-w{width: 55%;}
	.touP-w::after{
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		border-right: 2px solid #FD482C;
	}
</style>
<style>
	.van-rate__icon{font-size: 22px !important;}
	.van-dropdown-menu__bar{box-shadow: 0 0 0 #ffffff !important;}
</style>