<template>
	<div class="flx-1 bk-co-f5">
		<div class="mg-t-10 bk-co-ff">
			<div class="dis-flex-row ft-co-ff">
				<div class="mg-l-auto dis-flex-row">
					<div class="po-r" style="top: 4px;">
						<span class="pd-x-10 ft-sz-10 bk-co-fd4 pd-t-5 pd-b-5" style="border-bottom-left-radius: 20px;">
							{{util.formatDate('cn',dataObj.list.begintime)}}
						</span>
					</div>
					<div class="bk-co-fd pd-x-10 pd-t-5 pd-b-5 ft-sz-14" style="border-bottom-left-radius: 20px;">
						活动时间
					</div>
				</div>
			</div>
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16 ov-fl-h" style="-webkit-line-clamp: 1;">
				{{dataObj.list.partyname}}
			</div>
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row">
				<div class="bk-co-f5 flx-1 pd-x-10 pd-t-10 pd-b-10 bd-r-5 ft-sz-14" style="color: #5f5f5f;" v-html="dataObj.list.abstract"></div>
			</div>
			<div class="pd-x-10 pd-b-10 dis-flex-row ft-sz-18 ft-co-fd">
				参加人数:<span>{{dataObj.totalnumber}}</span>
			</div>
		</div>
		<div class="mg-t-10 bk-co-ff">
			<div class="pd-x-10 pd-t-10 pd-b-10 bd-bott ft-sz-16">
				填写信息
			</div>
			<div class="">
				<van-field v-model="info.remarks" :disabled="reportstatus" label="姓名" />
				<van-field v-model="info.mobile" :disabled="reportstatus" type="tel" label="手机号" />
				<van-field v-model="info.number" :disabled="reportstatus" type="digit" label="报名人数" />
				<div class="pd-x-15 pd-t-10 pd-b-10 bd-bott ft-sz-16">
					备注:
				</div>
				<div class="bd-bott">
					<van-field
					  v-model="info.note"
					  rows="4"
					  autosize
					  :disabled="reportstatus"
					  type="textarea"
					  maxlength="200"
					  placeholder="请输入备注内容"
					  show-word-limit
					/>
				</div>
			</div>
			<div v-if="!reportstatus">
				<div class="pd-x-10">
					<div class="mg-t-30">
						<button class="pd-y-10 w-100 ft-co-ff ft-sz-16" type="button" @click="huoDongSubmint">提交</button>
					</div>
				</div>
				<div class="pd-y-15"></div>
			</div>
		</div>
	</div>
</template>
<style scoped>
	button {border: none;color: #FFFFFF;border-radius: 30px;background: -webkit-linear-gradient(#f7514d, #fd704d);}
	button:focus {outline: none !important;}
</style>
<script>
	import http from '../../../lib/api.js'
	import util from '../../../lib/util.js'
	import {
		Toast
	} from 'vant';
	var {
		adminGeneral,
		huoDongJuAcions 
	} = http
	export default {
		name: 'Home',
		props: {
			userdata: {
				type: Object,
				default () {
					return {
						realname:'',
						usermobile:'',
						userid:'' 
					}
				}
			}
		},
		components: {},
		data() {
			return {
				util:util,
				info:{/*活动*/
					remarks:'',
					mobile:'', 
					number:0,
					note:''
				},
				dataObj:{
					list:{}
				},
				reportstatus:false
			}
		},
		created(){},
		mounted() {
			console.log(this.userData)
			this.info.remarks = this.userdata.realname;
			this.info.mobile = this.userdata.usermobile;
			this.huoDongList();
		},
		activated() {},
		methods: {
			huoDongList(){
				var s = this;
				var condition = {};
				condition = Object.assign(condition, {
					"condition":{
						"companyid":s.$store.state.companyid,
						"productid":s.$store.state.productid,
						"partyid":s.$route.params.id
					}
				})
				huoDongJuAcions.getActivityFormList.http({
					condition
				}).then(data => {
					if (data.getret === 0) {
						s.dataObj= data;
						if(s.$store.state.user.userid != ''){
							s.getActivitusereport();
						}
					}
				})
			},
			getActivitusereport(){
				var s = this;
				var condition = {};
				condition = Object.assign(condition, {
					"condition":{
						"companyid":s.$store.state.companyid,
						"productid":s.$store.state.productid,
						"partyid":s.$route.params.id
					}
				})
				huoDongJuAcions.getActivitusereport.http({
					condition
				}).then(data => {
					if(data.getret == 0 && data.isjoin==true){
						s.reportstatus = true;
						s.info.number = data.info.number;
						s.info.remarks = data.info.username;
						s.info.mobile = data.info.mobile;
						s.info.note = data.info.remarks;
					}
				})
			},
			huoDongSubmint(){
				var s = this;
				if(s.info.mobile == '') return Toast('手机号码不能为空');
				if(!/^[1][0-9]{10}$/.test(s.info.mobile )) return Toast('请输入合法的手机号');
				if(s.info.number < 0) return Toast('报名人数不能小于0人')
				var {info} = s;
				info = Object.assign(info, {
					"companyid":s.$store.state.companyid,
					"productid":s.$store.state.productid,
					"partyid":s.$route.params.id
				})
				huoDongJuAcions.getActivityFormSubmint.http({
					info
				}).then(data => {
					if (data.getret === 0) {
						Toast('提交成功')
						if(s.$route.params.type == 1){
							setTimeout(function(){
								s.$router.go(-1);
							},300)
						}else{
							setTimeout(function(){
								s.$router.push('/');
							},300)
						}
					}else{
						Toast('提交成功')
					}
				})
			},
		},
		destroyed() {}
	}
</script>
