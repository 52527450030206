<template>
	<div class="flx-1 bk-co-f5">
		<div class="mg-t-10 bk-co-ff">
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16">
				<div class="mg-r-auto ov-fl-h" style="-webkit-line-clamp: 1;">{{dataList.abstract}}</div>
			</div>
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c ft-co-99">
				<div class="mg-r-auto">
					{{dataList.totalnumbers}}人参与投票
				</div>
				<div v-if="jisuanTime(dataList.endtime)">
					距离结束还有:{{jisuanTime(dataList.endtime)}}天
				</div>
			</div>
		</div>
		<div class="mg-t-10 bk-co-ff">
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16">
				<div>
					<span class="zmt_iconfont ft-sz-22">&#xe606;</span>
				</div>
				<div class="mg-l-5">简介</div>
			</div>
			<div class="pd-x-10 pd-t-10 pd-b-10 ft-sz-14">
				{{dataList.abstract}}
			</div>
		</div>
		<div class="mg-t-10 bk-co-ff">
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott ft-sz-16">
				<div class="mg-r-auto">投票项</div>
			</div>
			<div class="pd-t-10 pd-x-5 pd-b-10 dis-flex-row" style="flex-wrap: wrap;">
				<div class="touPiao bd-r-5" style="box-shadow: 0 0 5px 0 #999999;" v-for="(item,index) in dataObj.options" :key="index" @click="touPiaoSubimt(item)">
					<div class="pd-x-5">
						<div class="img-w w-100">
							<img class="w-100" :src="item.optionsurl" alt="">
						</div>
						<div class="txt-c pd-t-10 pd-x-5 pd-b-10 ft-sz-16">
							{{item.options}}
						</div>
						<div class="txt-c pd-x-5 pd-b-10">
							<div class="bd-r-30 pd-b-10 pd-t-10 dis-flex-row al-c ft-sz-16 ft-co-fd" style="border: 2px solid #FD482C;">
								<div class="touP-w po-r">
									{{item.number}}
								</div>
								<div class="flx-1">
									投票
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
	.touPiao{width: 172px;padding: 0 5px;}
	.touPiao .img-w{height: 120px;overflow: hidden;}
	.touP-w{width: 55%;}
	.touP-w::after{
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		border-right: 2px solid #FD482C;
	}
</style>
<script>
	import http from '../../../lib/api.js'
	import util from '../../../lib/util.js'
	import axios from 'axios'
	import {
		Toast
	} from 'vant';
	var {
		adminGeneral,
		huoDongJuAcions 
	} = http
	export default {
		name: 'Home',
		props: {
			userdata: {
				type: Object,
				default () {
					return {
						realname:'',
						usermobile:'',
						userid:'' 
					}
				}
			}
		},
		components: {},
		data() {
			return {
				util:util,
				dataList:{
					abstract:'',
					totalnumbers:'',
					endtime:'',
					abstract:''
				},
				dataObj:{}
			}
		},
		created(){},
		mounted() {
			this.userVoteList();
		},
		activated() {},
		methods: {
			userVoteList(){/*用户获取投票列表 2001530*/
				var s = this;
				var condition = {};
				condition = Object.assign(condition, {
					page_index: 0,
					page_size: 1,
					"companyid":s.$store.state.companyid,
					"productid":s.$store.state.productid,
					"voteid":s.$route.params.id
				})
				adminGeneral.userVoteList.http({
					condition
				}).then(data => {
					if (data.getret === 0) {
						s.dataList = data.list[0];
						s.huoDongsJuAcions();
					}
				})
			},
			huoDongsJuAcions(){/*投票表单列表 2001431*/
				var s = this;
				var condition = {};
				condition = Object.assign(condition, {
					"condition":{
						"page_index":0,
						"page_size":1,
						"status": 1,
						"companyid":s.$store.state.companyid,
						"productid":s.$store.state.productid,
						"voteid":s.$route.params.id
					}
				})
				huoDongJuAcions.getVoteFormList.http({
					condition
				}).then(data => {
					if (data.getret === 0) {
						s.dataObj= data.list[0];
					}
				})
			},
			touPiaoSubimt(item){
				var s = this;
				var condition = {
					"action": "2001432"
				};
				if(s.dataList.isrealname != 0){
					condition = Object.assign(condition, {
						"list":[{
							questionid: item.questionid, 
							optionsids: [item.optionsid]
						}],
						"info": {
							voteid: s.dataList.voteid,
							vuid: s.dataList.vuid,
						},
						"user":{
							contact: s.userdata.usermobile,
							email: "",
							realname: s.userdata.realname,
							userid: s.$store.state.user.userid
						}
					})
				}else{
					condition = Object.assign(condition, {
						"list":[{
							questionid: item.questionid, 
							optionsids: [item.optionsid]
						}],
						"info": {
							voteid: s.dataList.voteid,
							vuid: s.dataList.vuid,
						},
						"user":{
							userid: ''
						}
					})
				}
				axios.post(s.util.H5API+'?name=getEvaluationStarSubimt',JSON.stringify(condition))
				.then(function (response) {
					if(response.data.getret == 0){
						Toast(response.data.msg);
						s.$router.go(-1);
					}
				})
				.catch(function (error) {
					console.log(error);
				});
			},
			jisuanTime(val){
				let s = this;
				if(new Date().getTime()/1000 > val) return false;
				var data = val - (new Date().getTime()/1000);
				return Math.floor(data/(24*60*60))
			},
		},
		destroyed() {}
	}
</script>
